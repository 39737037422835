export const colors = {
  grey: {
    base: '#CBCBCB'
  },
  base: '#2665ee',
  baseLight: '#f3f5f8',
  white: '#fff',
  black: '#000',
  defaultBg: '#f3f4f6',
  buttonDefault: '#2665ee',
  transparent: 'transparent',
  defaultBorder: '#a2a2a2',
  colorGray: '#E9EDF2',
  colorGrayLite: '#e9edf2',
  colorGrayDark: '#4a4a4a',
  colorGrayLine: '#e4e4e4',
  colorGrayText: '#9b9b9b',
  colorBlue: '#25A9E5',
  colorBlueLite: '#25a9e5',
  colorAzure: '#D6EFF9',
  colorGold: '#fed7b3',
  colorRed: '#c83b30',
  colorOrange: '#ff5722'
};

export const metrics = {
  headerBarHeight: '65px',
  padding: {
    small: 5,
    base: 10,
    large: 15,
    extraLarge: 30
  },
  margin: {
    small: 5,
    base: 10,
    large: 15,
    extraLarge: 30
  }
};

export const fonts = {
  fontWeight: {
    default: '300'
  },
  fontFamily: {
    default: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  }
};
