import React, { useState, useEffect, memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button, message, Select } from 'antd';
import { Modal } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import produce from 'immer';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FEATURES, TENANTS } from 'appConstants';
import { FormItem } from 'components/Layout/Form';
import { CampaignSelect, LoyaltyProgramSelect } from 'components/selects';
import { useFeatureFlags, useTenant, useTypedSelector } from 'hooks';
import { cdnURL } from 'utils/helpers';
import API from 'utils/request';

const ACCEPTS_FILES = {
  uob_uob: {
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    label: 'Accepted Format: .xlsx or .csv files only'
  }
};

const DEFAULT_ACCEPT_FILE = {
  accept:
    '.csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  label: 'Accepted Format: .txt, .xlsx or .csv files only'
};

export const getFileUploadSampleLink = (type: string, tenantName: string) => {
  switch (type) {
    case 'upload_merchants':
      if (tenantName === TENANTS?.SIAM_PIWAT || tenantName === TENANTS?.SIAM_PIWAT_PREPROD) {
        return cdnURL('sample_spw_upload_merchant.csv');
      } else {
        return cdnURL(`sample_${type}.csv`);
      }
    case 'upload_users':
      if (tenantName === TENANTS?.GLONEONE) {
        return cdnURL(`sample_globeone_${type}.csv`);
      } else {
        return cdnURL(`sample_${type}.csv`);
      }
    case 'issue_vouchers':
    case 'upload_transactions':
    case 'upload_tries':
    case 'update_voucher_status':
      return cdnURL(`sample_${type}.csv`);
    case 'upload_vouchers':
      return cdnURL(`sample_upload_vouchers.csv`);
    case 'bulk_enrol_users':
    case 'bulk_unenrol_users':
      return cdnURL(`sample_bulk_enrol.csv`);
    case 'create_points':
    case 'upload_points':
      return cdnURL(`sample_bulk_upload_points.csv`);
    case 'bulk_update_voucher_expiry':
      return cdnURL(`sample_bulk_update_voucher_expiry.csv`);
    case 'upload_loyalty_points':
      return cdnURL(`sample_bulk_upload_loyalty_points.csv`);
    case 'upload_triggers':
      return cdnURL(`sample_upload_triggers.csv`);
    case 'upload_audience':
      return cdnURL(`sample_upload_audience.csv`);
    default:
      return cdnURL('bulk_issue_sample.csv');
  }
};

const BulkActionModal = ({
  isOpen,
  closeModal,
  campaignId = null,
  onSuccess = () => {},
  onError = () => {}
}) => {
  const { values, setFieldValue } = useFormik({
    onSubmit: () => {}, //Just a placeholder to prevent TS error
    initialValues: {
      campaign_id: campaignId,
      loyalty_program_id: null
    }
  });
  const featureFlags = useFeatureFlags();
  const createPointsEnabled = featureFlags[FEATURES.CREATE_POINTS.key];
  const uploadUsersEnabled = featureFlags[FEATURES.BULK_ACTIONS_UPLOAD_USERS.key];

  const actions = useTypedSelector(state => state.global.bulkActions, shallowEqual);

  const bulkActions = useMemo(() => {
    return uploadUsersEnabled
      ? actions
      : actions.filter(action => action.file_type !== 'upload_users');
  }, [uploadUsersEnabled, actions]);

  const [type, setType] = useState(
    campaignId !== null
      ? 'upload_tries'
      : bulkActions && bulkActions[0]
      ? bulkActions[0].file_type
      : undefined
  );
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { name: tenantName } = useTenant();
  const campaignSelectable = type === 'upload_tries';
  const loyaltyProgramSelectable =
    type === 'upload_loyalty_rules' ||
    (createPointsEnabled && type === 'create_points') ||
    (tenantName === TENANTS?.HSBC && type === 'upload_points');

  const handleUpload = async e => {
    if (uploading) return;

    const { campaign_id, loyalty_program_id } = values;

    setUploading(true);

    const formData = new FormData();
    formData.append('type', type);
    formData.append('file[filename]', fileList[0].name);
    formData.append('file[file]', fileList[0]);

    if (campaignSelectable && campaign_id) {
      formData.append('upload_params[campaign_id]', campaign_id);
    }

    if (loyaltyProgramSelectable && loyalty_program_id) {
      formData.append('upload_params[loyalty_program_id]', String(loyalty_program_id));
    }

    try {
      await API.post('/v4/dash/file_imports', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setUploading(false);
      setFileList([]);
      message.success('File uploaded');
      onSuccess();
      closeModal();
    } catch (err) {
      if (!axios.isCancel(err)) {
        setUploading(false);
        message.error('Error uploading file.');
        onError();
      }
    }
  };

  useEffect(() => {
    if (!campaignSelectable) {
      setFieldValue('campaign_id', undefined);
    }
    if (!loyaltyProgramSelectable) {
      setFieldValue('loyalty_program_id', undefined);
    }
  }, [setFieldValue, campaignSelectable, loyaltyProgramSelectable]);

  useEffect(() => {
    if (campaignId) {
      setType('upload_tries');
      setFieldValue('campaign_id', campaignId);
    }
  }, [setFieldValue, campaignId]);

  const fileUpload = ACCEPTS_FILES[tenantName] || DEFAULT_ACCEPT_FILE;

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="Bulk Upload"
      onCancel={closeModal}
      onOk={handleUpload}
      okText="Upload"
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={uploading}
          onClick={handleUpload}
          disabled={uploading || fileList.length === 0}
        >
          Upload
        </Button>
      ]}
    >
      <form onSubmit={handleUpload} aria-label="form" data-testid="bulk-action-modal-form">
        {!campaignId && (
          <>
            <FormItem label="Action type">
              <Select
                value={type}
                onChange={setType}
                placeholder="Choose one..."
                allowClear
                data-testid="file_type-select"
              >
                {bulkActions?.map(item => (
                  <Select.Option value={item.file_type} key={item.file_type}>
                    {item.file_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            {campaignSelectable && (
              <FormItem label="Campaign" htmlFor="campaign_id">
                <CampaignSelect
                  id="campaign_id"
                  showArrow
                  showSearch
                  showId
                  value={values.campaign_id}
                  onChange={value => setFieldValue('campaign_id', value)}
                  placeholder="Please select a campaign"
                  params={{ campaign_type: ['game', 'stamp'] }}
                  data-testid="campaign-select"
                />
              </FormItem>
            )}
            {loyaltyProgramSelectable && (
              <FormItem label="Loyalty program">
                <LoyaltyProgramSelect
                  value={values.loyalty_program_id}
                  onChange={value => setFieldValue('loyalty_program_id', value)}
                  data-testid="loyalty-select"
                  placeholder="Please select a loyalty program"
                />
              </FormItem>
            )}
          </>
        )}

        {type && (
          <UploadWrapper isFileSelected={fileList.length > 0}>
            <Upload.Dragger
              accept={fileUpload.accept}
              name="file"
              beforeUpload={file => {
                if (fileList.length < 1) {
                  setFileList(prev => [...prev, file]);
                }
                return false;
              }}
              fileList={fileList}
              onRemove={file => {
                setFileList(
                  produce(prev => {
                    const index = prev.indexOf(file);
                    prev.splice(index, 1);
                  })
                );
              }}
              style={{ marginBottom: 16 }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Upload.Dragger>
            <div>
              <a href={getFileUploadSampleLink(type, tenantName)}>Download Sample</a>
              <div>{fileUpload.label}</div>
            </div>
          </UploadWrapper>
        )}
      </form>
    </Modal>
  );
};

const UploadWrapper = styled.div<{ isFileSelected: boolean }>`
  .ant-upload,
  .ant-upload-drag {
    display: ${props => (props.isFileSelected ? 'none' : 'block')};
  }
`;

BulkActionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  campaignId: PropTypes.number,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

export default memo(BulkActionModal);
